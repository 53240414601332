<template>
  <div class="book-card">
    <div class="book-card-row">
      <div class="book-card-img-col">
        <router-link
          :to="`/book/${categorySlug}/${book.slug}-${book.bookId}`"
          data-testid="bookImageLink"
        >
          <img
            :id="book.bookId"
            :alt="book.title"
            :src="book.image.src"
            class="book-img"
            loading="lazy"
          >
        </router-link>
      </div>
      <div class="book-card-content-col">
        <router-link
          :to="`/category/books/${book.category.slug}-${book.category.categoryId}`"
          data-testid="bookCategoryName"
        >
          <b>{{ book.category.title }}</b>
        </router-link>
        <h3 data-testid="bookTitle">
          {{ book.title }}
        </h3>
        <router-link
          :to="`/book/${categorySlug}/${book.slug}-${book.bookId}`"
          data-testid="bookExploreLink"
        >
          <b class="link-underline">
            Explore Book
          </b>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BookCard',
  props: {
    book: {
      type: Object,
      required: true,
      validator: function (books) {
        const keys = ['bookId', 'title', 'category', 'image', 'categoryList']
        return keys.every(key => key in books)
      }
    }
  },
  computed: {
    categorySlug () {
      return this.book.categoryList.join('/')
    }
  }
}
</script>
<style scoped lang="scss">
.book-card {
  width: 100%;
  border-radius: 0;
  border: 1px solid $grey-8;
  position: relative;
  height: 100%;

  .book-card-row {
    margin: 32px 24px;
    display: flex;

    .book-card-img-col {
      display: block;
      width: 37%;

      .book-img {
        width: 100%;
        height: auto;
      }
    }

    .book-card-content-col {
      display: block;
      width: 63%;
      background-color: $white;
      padding-left: 24px;

      h3 {
        @extend %typography-h3;

        padding-bottom: 16px;
        padding-top: 16px;
      }

      b {
        @extend %typography-b1;

        font-weight: 600;
        color: $red-1;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .link-underline {
        color: $black;

        &:hover {
          color: $grey-3;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-md-max) {
  .book-card {
    .book-card-row {
      .book-card-img-col {
        width: 40%;
      }

      .book-card-content-col {
        width: 60%;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-sm-max) {
  .book-card {
    .book-card-row {
      .book-card-img-col {
        width: 45%;
      }

      .book-card-content-col {
        width: 55%;
        padding-left: 16px;
      }
    }
  }
}
</style>
