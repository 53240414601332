<template>
  <div class="featured-collection-group">
    <h1 data-testid="featuredCollectionTitle">
      {{ collectionTitle }}
    </h1>
    <div class="spacing" />
    <b-row no-gutter>
      <b-col
        v-for="(collection, i) of homeTemplateState.collections"
        :key="i"
        xl="6"
        lg="6"
        md="6"
        sm="12"
        class="col-pad"
      >
        <collection-card :collection="collection" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { homeTemplateState } from '../../store/home/selector'
import {
  BRow,
  BCol
} from 'bootstrap-vue'
import CollectionCard from '../molecules/CollectionCard'

export default {
  name: 'FeaturedCollectionsGroup',
  components: {
    BRow,
    BCol,
    CollectionCard
  },
  data: function () {
    return {
      collectionTitle: 'Featured Collections'
    }
  },
  computed: {
    ...mapState({
      homeTemplateState: state => homeTemplateState(state)
    })
  }
}
</script>
<style scoped lang="scss">
.featured-collection-group {
  width: 100%;

  h1 {
    @extend %typography-h1;
  }

  .spacing {
    height: $space-x-lg;
  }

  .row {
    margin-right: -12px;
    margin-left: -12px;

    div[class*=col-] {
      padding-right: 12px;
      padding-left: 12px;
    }
  }

  .col-pad {
    padding-top: 24px;
  }
}

@media only screen and (max-width: $breakpoint-md-max) {
  .featured-collection-group {
    .spacing {
      height: $space-md;
    }
  }
}

@media only screen and (max-width: $breakpoint-sm-max) {
  .featured-collection-group {
    .spacing {
      height: $space-x-sm;
    }

    .col-pad {
      padding-top: 16px;
    }
  }
}
</style>
