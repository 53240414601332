<template>
  <div class="custom-card-group">
    <b-row no-gutter>
      <b-col
        v-for="(book, i) of books"
        :key="i"
        xl="6"
        lg="6"
        md="6"
        sm="12"
        class="col-pad"
      >
        <book-card :book="book" />
      </b-col>
    </b-row>
    <b-row no-gutter>
      <b-col
        v-for="(article, i) of articles"
        :key="i"
        class="col-pad"
      >
        <article-card :article="article" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol
} from 'bootstrap-vue'
import ArticleCard from '../molecules/ArticleCard'
import BookCard from '../molecules/BookCard'

export default {
  name: 'CardGroup',
  components: {
    BRow,
    BCol,
    ArticleCard,
    BookCard
  },
  props: {
    books: {
      type: Array,
      required: true,
      validator: function (books) {
        const keys = ['bookId', 'title', 'category', 'image', 'categoryList']
        return books.every(type => keys.every(key => key in type))
      }
    },
    articles: {
      type: Array,
      required: true,
      validator: function (articles) {
        const keys = ['articleId', 'title', 'category', 'categoryList']
        return articles.every(type => keys.every(key => key in type))
      }
    }
  }
}
</script>
<style scoped lang="scss">
.custom-card-group {
  width: 100%;

  .row {
    margin-right: -12px;
    margin-left: -12px;

    div[class*=col-],
    .col {
      padding-right: 12px;
      padding-left: 12px;
    }
  }

  .col-pad {
    padding-top: 24px;
  }
}

@media only screen and (max-width: $breakpoint-sm-max) {
  .custom-card-group {
    .col-pad {
      padding-top: 16px;
    }
  }
}
</style>
