<template>
  <div class="news-letter-signup">
    <b-container class="custom-container">
      <b-row>
        <b-col
          lg="12"
          md="12"
          sm="12"
        >
          <div class="inner">
            <h2
              class="h2"
              data-testid="newsLetterHead"
            >
              Sign Up For Our Daily Dummies Newsletter
            </h2>
          </div>

          <div
            v-if="toggle"
            data-testid="newsLetterFormEmailBlock"
          >
            <form class="sign-up-form">
              <input
                v-model="emailInput"
                type="email"
                placeholder="E-mail"
                required
                class="b1"
                data-testid="newsLetterFormEmail"
              >
              <custom-button
                type="submit"
                class="button"
                label="Sign Up"
                :tertiary="true"
                data-testid="newsLetterFormSubmit"
                @click="onClick"
              />
            </form>
            <p
              class="sign-up-text"
              data-testid="newsLetterText"
            >
              By signing up to the Dummies newsletter you agree to receive electronic communications from DUMMIES that may sometimes include advertisements or sponsered content.
            </p>
          </div>

          <div
            v-else
            class="sign-out-form"
          >
            <p
              class="sign-out-text"
              data-testid="newsLetterConfirmText"
            >
              Thank you for signing up!
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { BRow, BCol, BContainer } from 'bootstrap-vue'
import CustomButton from '../atoms/CustomButton'
export default {
  name: 'NewsLetterForm',
  components: { CustomButton, BRow, BCol, BContainer },
  data: function () {
    return {
      emailInput: '',
      toggle: true,
      reg: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    }
  },
  methods: {
    onClick: function () {
      if (this.reg.test(this.emailInput)) {
        this.toggle = false
        this.emailInput = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.news-letter-signup {
  background-color: $yellow-3;
  height: 260px;
  display: block;
}

div.inner {
  h2 {
    padding-top: 40px;
    margin: 0;
    color: $black;
    font-weight: 800;
  }
}

.sign-up-form {
  padding-top: 40px;
  padding-bottom: 40px;

  input {
    width: calc(100% - 115px);
    height: 40px;
    border: none;
    float: left;
    background: $white 0% 0% no-repeat padding-box;
    padding: 8px 16px;
    outline: none;
    box-sizing: border-box;
  }

  input[type="email"]:focus {
    border: 1px solid #000;
  }

  button {
    width: 115px;
    height: 40px;
    color: $white;
    background: $black 0% 0% no-repeat padding-box;
    outline: none;
  }

  button[type="submit"]:focus {
    border: 1px solid #000;
  }

  button[type="submit"]:hover {
    background: $grey-3;
  }
}

.sign-up-text {
  font-size: 0.75rem;
  line-height: 20px;
  padding-bottom: 40px;
}

.sign-out-form {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media only screen and (max-width: $breakpoint-md-max) {
  .news-letter-signup {
    height: 252px;
    display: block;
  }

  .container.custom-container {
    padding-left: 24px;
    padding-right: 24px;
  }

  div.inner {
    h2 {
      padding-top: 32px;
    }
  }

  .sign-up-form {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .sign-up-text {
    padding-bottom: 32px;
  }

  .sign-out-form {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

@media only screen and (max-width: $breakpoint-sm-max) {
  .news-letter-signup {
    height: 358px;
    display: block;
  }

  .container.custom-container {
    padding-left: 16px;
    padding-right: 16px;
  }

  .sign-up-form {
    padding-top: 32px;
    padding-bottom: 32px;

    input {
      width: 100%;
      float: none;
      margin-bottom: 16px;
    }

    button {
      width: 100%;
    }
  }

  .sign-up-text {
    padding-bottom: 32px;
  }

  .sign-out-form {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
</style>
