<template>
  <div class="collection-card">
    <b-card>
      <router-link
        :to="`/collection/${collection.slug}-${collection.collectionId}`"
        data-testid="collectionCardImageLink"
      >
        <img
          :src="collection.image.src"
          :alt="collection.title"
          class="rounded-0 collection-card-img"
          start
          loading="lazy"
        >
      </router-link>
      <div class="collection-card-body">
        <h2 data-testid="collectionCardTitle">
          {{ collection.title }}
        </h2>
        <b-card-text data-testid="collectionCardContent">
          {{ collection.description }}
        </b-card-text>
        <router-link
          :to="`/collection/${collection.slug}-${collection.collectionId}`"
          data-testid="collectionCardExploreLink"
        >
          <b class="link-underline">
            Explore Collection
          </b>
        </router-link>
      </div>
    </b-card>
  </div>
</template>
<script>
import { BCard, BCardText } from 'bootstrap-vue'
export default {
  name: 'CollectionCard',
  components: { BCard, BCardText },
  props: {
    collection: {
      type: Object,
      required: true,
      validator: function (collection) {
        const keys = ['collectionId', 'title', 'slug', 'description', 'image']
        return keys.every(key => key in collection)
      }
    }
  }

}
</script>
<style lang="scss" scoped>
.collection-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: wrap;

  .card {
    width: 100%;
    border-radius: 0;
    border: 1px solid $grey-2;
  }

  .card-body {
    padding: 0;
  }

  .collection-card-img {
    border-radius: 0;
    width: 100%;
    height: auto;
  }

  .collection-card-body {
    padding: 32px 40px 32px 40px;

    p {
      @extend %typography-b1;

      padding: 24px 0 24px 0;
    }

    h2 {
      @extend %typography-h2;
    }

    b {
      @extend %typography-b1;

      font-weight: 600;
    }
  }
}

@media only screen and (max-width: $breakpoint-md-max) {
  .collection-card {
    .collection-card-body {
      padding: 32px;

      p {
        padding: 16px 0 16px 0;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-sm-max) {
  .collection-card {
    .collection-card-body {
      padding: 24px;
    }
  }
}
</style>
