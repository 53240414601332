import { ApiStatus } from '../../api/constants/ApiConstants'

export const homeTemplateState = state => {
  const {
    homeState: {
      homeLoadedStatus = ApiStatus.initial,
      home: {
        mainItem = {},
        categories = [],
        collections = []
      } = {}
    } = {}
  } = state
  return {
    homeLoadedStatus,
    mainItem,
    categories,
    collections
  }
}
