<template>
  <home-template />
</template>

<script>
import HomeTemplate from '../components/templates/HomeTemplate'
import { mapActions } from 'vuex'
import { AppStateModuleName } from '../store/config'

export default {
  name: 'HomePage',
  components: {
    HomeTemplate
  },
  async created () {
    await this.registerState()
    this.getHomeData()
  },
  methods: {
    ...mapActions({
      getHomeDataAction: 'getHomeDataAction'
    }),
    async registerState () {
      const homeModule = await import('../store/home')
      if (!this.$store.hasModule(AppStateModuleName.home)) {
        this.$store.registerModule(AppStateModuleName.home, homeModule.default)
      }
    },
    getHomeData () {
      this.getHomeDataAction()
    }
  }
}
</script>
