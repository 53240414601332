<template>
  <div class="article-card">
    <div class="article-card-row">
      <div class="article-card-content-col">
        <router-link
          :to="`/category/articles/${article.category.slug}-${article.category.categoryId}`"
          data-testid="categoryLink"
        >
          <b>{{ article.category.title }}</b>
        </router-link>
        <h3
          class="title-name"
          data-testid="bookTitle"
        >
          {{ article.title }}
        </h3>
        <router-link
          :to="`/article/${categorySlug}/${article.slug}-${article.articleId}`"
          data-testid="exploreLink"
        >
          <b
            class="link-underline"
          >View Article</b>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'ArticleCard',
  components: { },
  props: {
    article: {
      type: Object,
      required: true,
      validator: function (article) {
        const keys = ['articleId', 'title', 'category', 'categoryList']
        return keys.every(key => key in article)
      }
    }
  },
  computed: {
    categorySlug () {
      return this.article.categoryList.join('/')
    }
  }
}
</script>
<style scoped lang="scss">
.article-card {
  width: 100%;
  height: 100%;
  border-radius: 0;
  border: 1px solid $grey-8;
  position: relative;

  .article-card-row {
    margin: 32px 24px;

    .article-card-content-col {
      display: block;
      background-color: $white;

      h3 {
        @extend %typography-h3;

        padding-bottom: 16px;
        padding-top: 16px;
      }

      b {
        @extend %typography-b1;

        font-weight: 600;
        color: $red-1;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .link-underline {
        color: $black;

        &:hover {
          color: $grey-3;
        }
      }
    }
  }
}
</style>
