<template>
  <article class="home-page">
    <spinner
      v-if="!homeTemplateState.homeLoadedStatus || homeTemplateState.homeLoadedStatus === ApiStatus.progress"
      has-overlay
    />
    <b-container
      v-else
      class="custom-home-container"
    >
      <b-row>
        <b-col
          xl="12"
          lg="12"
          md="12"
          sm="12"
        >
          <header-block
            v-if="homeTemplateState.homeLoadedStatus == ApiStatus.success"
            :type="homeTemplateState.mainItem.mainType"
            :title="homeTemplateState.mainItem.title"
            :description="homeTemplateState.mainItem.description"
            :collection-path="`/${homeTemplateState.mainItem.mainType.toLowerCase()}/${homeTemplateState.mainItem.slug}-${homeTemplateState.mainItem.typeId}`"
            :background-image-url="homeTemplateState.mainItem.image.src"
          />
          <featured-collections-group
            v-if="homeTemplateState.collections.length>0"
            class="top-spacing-lg"
          />
          <h1
            data-testid="exploreCategoriesTitle"
            class="top-spacing-lg"
          >
            {{ exploreCategoriesTitle }}
          </h1>
          <div
            v-for="(category, i) of homeTemplateState.categories"
            :key="i"
          >
            <header-block
              class="top-spacing-md"
              data-testid="categoryHeader"
              :type="'Category'"
              :title="category.title"
              :description="category.description"
              :books-path="`/category/books/${category.slug}-${category.categoryId}`"
              :articles-path="`/category/articles/${category.slug}-${category.categoryId}`"
              :background-image-url="category.image.src"
            />
            <card-group
              :books="category.relatedBooks"
              :articles="category.relatedArticles"
            />
          </div>
          <newsletter-form class="top-spacing-lg bottom-spacing-lg" />
        </b-col>
      </b-row>
    </b-container>
  </article>
</template>

<script>
import { mapState } from 'vuex'
import { homeTemplateState } from '../../store/home/selector'
import { ApiStatus } from '../../api/constants/ApiConstants'
import { BContainer, BRow, BCol } from 'bootstrap-vue'
import CardGroup from '../organisms/CardGroup'
import HeaderBlock from '../organisms/HeaderBlock'
import FeaturedCollectionsGroup from '../organisms/FeaturedCollectionsGroup'
import NewsletterForm from '../molecules/NewsletterForm'
import Spinner from '../atoms/Spinner'

export default {
  name: 'HomeTemplate',
  components: { BContainer, BRow, BCol, CardGroup, HeaderBlock, FeaturedCollectionsGroup, NewsletterForm, Spinner },
  data () {
    return {
      exploreCategoriesTitle: 'Explore Categories'
    }
  },
  computed: {
    ...mapState({
      homeTemplateState: state => homeTemplateState(state)
    }),
    ApiStatus: function () {
      return ApiStatus
    }
  }
}
</script>

<style lang="scss" scoped>
.home-page {
  .container.custom-home-container {
    width: 100%;
    max-width: $pg-max-width;
    margin: 0 auto;
    padding: 40px;

    .top-spacing-lg {
      margin-top: 80px;
    }

    .top-spacing-md {
      margin-top: 64px;
    }

    .bottom-spacing-lg {
      margin-bottom: 80px;
    }

    h1 {
      @extend %typography-h1;
    }

    @media only screen and (max-width: $breakpoint-md-max) {
      width: 100%;
      max-width: 100%;
      padding: 24px;

      .top-spacing-lg {
        margin-top: 64px;
      }

      .top-spacing-md {
        margin-top: 48px;
      }

      .bottom-spacing-lg {
        margin-bottom: 64px;
      }
    }

    @media only screen and (max-width: $breakpoint-sm-max) {
      padding: 16px;

      .top-spacing-lg {
        margin-top: 48px;
      }

      .top-spacing-md {
        margin-top: 32px;
      }

      .bottom-spacing-lg {
        margin-bottom: 48px;
      }
    }
  }
}
</style>
